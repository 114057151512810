var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('main', {
    class: {
      accepted: _vm.accepted
    },
    attrs: {
      "id": "__members-area"
    }
  }, [_c('loading-overlay'), _vm._v(" "), _vm.showStepsProgression ? _c('progress-indicator', {
    staticClass: "mb-12",
    attrs: {
      "step": 1
    }
  }) : _vm._e(), _vm._v(" "), _vm._m(0), _vm._v(" "), _c('transition', {
    attrs: {
      "name": "fade",
      "mode": "out-in"
    }
  }, [!_vm.codeSent ? _c('section', {
    key: 'phone',
    staticClass: "section--phone"
  }, [_c('p', {
    staticClass: "relative z-10 mt-8 -mb-8 text-center transition duration-150",
    class: !_vm.notFound ? 'opacity-0' : 'opacity-100'
  }, [_c('span', [_vm._v("\n\t\t\t\t\tSeems like you're having trouble."), _c('br'), _vm._v("\n\t\t\t\t\tMake sure you're using the number you signed up with.\n\t\t\t\t")])]), _vm._v(" "), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.phone,
      expression: "phone"
    }, {
      name: "mask",
      rawName: "v-mask",
      value: ['###-###-####', '+##-##########'],
      expression: "['###-###-####', '+##-##########']"
    }],
    class: {
      invalid: _vm.invalid
    },
    attrs: {
      "name": "phone",
      "type": "tel",
      "placeholder": "Enter your phone number"
    },
    domProps: {
      "value": _vm.phone
    },
    on: {
      "keyup": function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.submitPhone.apply(null, arguments);
      },
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.phone = $event.target.value;
      }
    }
  }), _vm._v(" "), _c('button', {
    staticClass: "button--filled sm:w-full",
    attrs: {
      "disabled": _vm.phoneBtnDisabled
    },
    on: {
      "click": _vm.submitPhone
    }
  }, [_vm._v("\n\t\t\t\t" + _vm._s(_vm.processing ? 'Checking...' : 'Enter') + "\n\t\t\t")])]) : _c('section', {
    key: 'code',
    staticClass: "section--verify"
  }, [_c('p', {
    staticClass: "mt-8 -mb-8"
  }, [_vm._v("Enter the code we texted to " + _vm._s(_vm.formatPhone(_vm.phone)))]), _vm._v(" "), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.code,
      expression: "code"
    }, {
      name: "mask",
      rawName: "v-mask",
      value: '###-###',
      expression: "'###-###'"
    }],
    class: {
      invalid: _vm.invalid
    },
    attrs: {
      "name": "code",
      "type": "text",
      "pattern": "[0-9]*",
      "maxlength": "7",
      "required": "",
      "placeholder": "Verification code"
    },
    domProps: {
      "value": _vm.code
    },
    on: {
      "keyup": function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.submitCode.apply(null, arguments);
      },
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.code = $event.target.value;
      }
    }
  }), _vm._v(" "), _c('a', {
    staticClass: "block mb-6 -mt-12 transition-all duration-200 text-primary",
    class: !_vm.secondCodeSent && _vm.secondCodeReady ? 'opacity-100' : 'opacity-0 pointer-events-none',
    attrs: {
      "href": "#"
    },
    on: {
      "click": _vm.sendSecondCode
    }
  }, [_vm._v("\n\t\t\t\tSend again?\n\t\t\t")]), _vm._v(" "), _vm.showStepsProgression ? _c('div', {
    staticClass: "max-w-sm my-8 leading-6 pl-10 text-left disclosure"
  }, [_vm._v("\n\t\t\t\t\tBy clicking Continue, I agree that Extra and affiliated companies may contact me via\n\t\t\t\t\tphone call. Calls may be recorded for quality control and training purposes.\n\t\t\t")]) : _vm._e(), _vm._v(" "), _vm.showStepsProgression ? _c('div', {
    staticClass: "max-w-sm my-8 flex leading-6 text-left disclosure"
  }, [_c('input', {
    staticStyle: {
      "margin": "5px"
    },
    attrs: {
      "id": "sms-consent",
      "type": "checkbox",
      "value": "sms-consent"
    },
    on: {
      "change": () => _vm.policyChange('sms_consent')
    }
  }), _vm._v(" "), _c('label', {
    staticClass: "pl-2",
    attrs: {
      "for": "sms-consent"
    }
  }, [_vm._v("\n\t\t\t\t\tI agree to receive SMS messaging from Extra and understand that standard msg & data rates may apply.\n\t\t\t\t\tReply STOP to cancel, HELP for help. Msg freq. varies.\n\t\t\t\t")])]) : _vm._e(), _vm._v(" "), _c('button', {
    staticClass: "button--filled sm:w-full",
    attrs: {
      "disabled": _vm.codeBtnDisabled
    },
    on: {
      "click": _vm.submitCode
    }
  }, [_vm._v("\n\t\t\t\t" + _vm._s(_vm.processing ? 'Checking...' : 'Continue') + "\n\t\t\t")])])])], 1);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('a', {
    attrs: {
      "href": "/"
    }
  }, [_c('img', {
    staticClass: "logo",
    attrs: {
      "src": require("assets/images/extra-logo-white.svg"),
      "alt": "Extra logo"
    }
  })]);
}];
export { render, staticRenderFns };