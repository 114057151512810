import _ from 'lodash';
import { VueMaskFilter } from 'v-mask';
import LoadingOverlay from '~/components/common/LoadingOverlay.vue';
import ProgressIndicator from '~/components/common/ProgressIndicator.vue';
export default {
  components: {
    LoadingOverlay,
    ProgressIndicator
  },
  layout: 'password',
  props: {
    redirect: String,
    analyticsClass: String
  },
  data() {
    return {
      showStepsProgression: false,
      accepted: false,
      phone: '',
      code: '',
      invalid: false,
      notFound: false,
      processing: false,
      codeSent: false,
      invalidCounter: 0,
      secondCodeReady: false,
      secondCodeTimeout: null,
      secondCodeSent: false,
      smsConsent: false,
      smsConsentDate: null
    };
  },
  computed: {
    phoneBtnDisabled() {
      return this.processing || !this.phone || this.phone.length < 12;
    },
    codeBtnDisabled() {
      return this.processing || !this.code || this.code.length < 7;
    }
  },
  watch: {
    phone() {
      this.notFound = false;
    },
    invalid(val) {
      if (val) {
        setTimeout(() => {
          this.invalid = false;
        }, 400);
        this.invalidCounter++;
      }
    },
    accepted(val) {
      if (val) {
        this.$passwordProtect.authorise('getextra');
        setTimeout(() => {
          this.$router.push({
            path: this.redirect,
            query: this.$route.query
          });
        }, 1000);
      }
    },
    smsConsent(val) {
      this.smsConsentDate = val ? new Date() : null;
    }
  },
  mounted() {
    window.extra_data_ul.log([{
      kingdom: 'view',
      phylum: 'page',
      class: this.analyticsClass,
      // 'members_area',
      subclass: 'login'
    }]);
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: '101_login_start'
    });
    window._login = (token, id) => {
      this.setToken(token, id);
    };
    const forceId = this.$route.query.id;
    const forceToken = this.$route.query.token;
    if (this.$route.query.redirect) {
      this.redirect = this.$route.query.redirect || '/members';
    }
    const phonePrefill = this.$cookies.get('members_phone') || this.$route.query.phone;
    if (forceId && forceToken) {
      this.setToken(forceToken, forceId);
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: '105_login_complete'
      });
    } else if (phonePrefill) {
      this.phone = VueMaskFilter(`${phonePrefill}`, '###-###-####');
      this.codeSent = true;
      this.submitPhone();
      this.$cookies.remove('members_phone');
    }
    if (window.sessionStorage.getItem('funnel-steps-progression-login')) {
      this.showStepsProgression = true;
      window.sessionStorage.removeItem('funnel-steps-progression-login');
    }
  },
  methods: {
    sendSecondCode() {
      this.code = '';
      this.secondCodeSent = true;
      this.submitPhone();
    },
    submitPhone() {
      this.secondCodeReady = false;
      if (!this.phone || this.phone.length < 12) {
        this.invalid = true;
      } else {
        this.processing = true;
        this.$axios.$post('/api/members/auth', {
          phone: this.phone.replace(/[^\d+]/g, '')
        }).then(({
          sent
        }) => {
          this.codeSent = sent;
          this.secondCodeReady = false;
          clearTimeout(this.secondCodeTimeout);
          this.secondCodeTimeout = setTimeout(() => {
            this.secondCodeReady = true;
          }, 7000);
        }).catch(() => {
          this.codeSent = false;
          this.invalid = true;
          this.notFound = true;
        }).finally(() => {
          this.processing = false;
          window.extra_data_ul.log([{
            kingdom: 'engagement',
            phylum: 'input',
            class: this.analyticsClass,
            subclass: 'login',
            family: 'phone_field',
            genus: !this.invalid ? 'valid' : 'invalid',
            species: this.phone
          }]);
          window.dataLayer = window.dataLayer || [];
          window.dataLayer.push({
            event: '102_login_code_requested'
          });
        });
      }
    },
    submitCode() {
      this.processing = true;

      // pass affid to /verify (used for NDR plan reset check)
      const params = new URLSearchParams(_.pick(this.$route.query, ['affid']));
      const queryString = params.size ? `?${params.toString()}` : '';
      let memberId;
      this.$axios.$post(`/api/members/auth/verify${queryString}`, {
        phone: this.phone.replace(/[^\d+]/g, ''),
        code: this.code.replace(/[^\d+]/g, '')
      }).then(({
        token,
        id
      }) => {
        this.setToken(token, id);
        memberId = id;

        // once member has verified, submit policy agreement
        if (this.smsConsent) {
          this.$axios.$post('/api/optional-policies', {
            member_id: memberId,
            policy: 'sms_consent',
            policy_date: this.smsConsentDate
          }).catch(err => {
            console.warn('Non-fatal error in optional-policies endpoint', err);
          });
        }
      }).catch(err => {
        this.invalid = true;
        if (err.response.status === 410) {
          alert('Your code expired. Try again');
          this.codeSent = false;
          this.phone = '';
          this.code = '';
        }
      }).finally(() => {
        this.processing = false;
        window.extra_data_ul.log([{
          kingdom: 'engagement',
          phylum: 'input',
          class: this.analyticsClass,
          subclass: 'login',
          family: 'passcode_field',
          genus: this.accepted ? 'valid' : 'invalid',
          species: this.code
        }]);
        if (this.accepted) {
          window.dataLayer = window.dataLayer || [];
          window.dataLayer.push({
            event: '105_login_complete'
          });
        } else {
          window.dataLayer = window.dataLayer || [];
          window.dataLayer.push({
            event: '103_login_fail'
          });
        }
      });
    },
    setToken(token, id) {
      this.$cookies.set('members_token', token, {
        path: '/'
      });
      this.$cookies.set('member_id', id && `member_${id}`, {
        path: '/'
      });
      window.sessionStorage.setItem('EXTRA_MEMBER_TOKEN', token);
      this.$analyticsHub.identify(id);
      this.accepted = true;
    },
    formatPhone(phoneNumberString) {
      const cleaned = `${phoneNumberString}`.replace(/\D/g, '');
      const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
      if (match) {
        const intlCode = match[1] ? '+1 ' : '';
        return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
      }
      return phoneNumberString;
    },
    policyChange(name) {
      // only policy on this page for now is SMS
      this.smsConsent = !this.smsConsent;
    }
  }
};