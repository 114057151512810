import dayjs from 'dayjs';
export default {
  model: {
    event: 'changed'
  },
  props: {
    tracking: Object,
    waitFor: Boolean,
    reason: String,
    subReason: String,
    hideLoader: Boolean,
    light: Boolean,
    fillColor: {
      type: String,
      default: '#949494'
    },
    progressBarTime: {
      // ms
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      loadStart: dayjs(),
      loaded: false,
      targetState: 'complete',
      // | complete,
      maxTimeout: 5000
    };
  },
  computed: {
    progressBar() {
      return this.progressBarTime > 0;
    }
  },
  watch: {
    loaded(value) {
      this.$emit('changed', value);
      console.log('loaded', value);
      if (!value) {
        setTimeout(() => {
          this.loaded = true;
        }, this.maxTimeout);
      }
      if (value && this.tracking) {
        window.extra_data_ul.log([{
          kingdom: 'view',
          phylum: 'page',
          float4: dayjs().diff(this.loadStart, 'seconds', true),
          ...this.tracking
        }]);
      }
    }
  },
  mounted() {
    this.loaded = false;
    this.$nextTick(() => {
      this.loaded = document.readyState === this.targetState;
    });
    document.onreadystatechange = () => {
      if (document.readyState === this.targetState) {
        setTimeout(() => {
          this.loaded = true;
        }, 300);
      }
    };
    setTimeout(() => {
      this.loaded = true;
    }, this.maxTimeout);
  }
};