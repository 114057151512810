var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('transition', {
    attrs: {
      "name": "fade"
    }
  }, [_c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.loaded || _vm.waitFor,
      expression: "!loaded || waitFor"
    }],
    class: {
      light: !!_vm.light,
      noLoader: _vm.hideLoader
    },
    attrs: {
      "id": "overlay"
    }
  }, [!_vm.hideLoader ? _c('svg', {
    attrs: {
      "width": _vm.progressBar ? 120 : 60,
      "height": _vm.progressBar ? 120 : 60,
      "viewBox": "0 0 345 215",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('mask', {
    attrs: {
      "id": "mask0",
      "mask-type": "alpha",
      "maskUnits": "userSpaceOnUse",
      "x": "0",
      "y": "0",
      "width": "347",
      "height": "215"
    }
  }, [_c('rect', {
    attrs: {
      "x": "0",
      "width": "345",
      "height": "215",
      "fill": "#C4C4C4"
    }
  })]), _vm._v(" "), _c('g', {
    attrs: {
      "mask": "url(#mask0)"
    }
  }, [_c('mask', {
    attrs: {
      "id": "mask1",
      "mask-type": "alpha",
      "maskUnits": "userSpaceOnUse",
      "x": "-173",
      "y": "50",
      "width": "694",
      "height": "115"
    }
  }, [_c('path', {
    attrs: {
      "d": "M-166 157.5H80.8991C107.289 157.5 132.597 147.398 151.257 129.416L196.743 85.5834C215.403 67.6015 240.712 57.5 267.101 57.5H514",
      "stroke": "#717171",
      "stroke-width": "14",
      "stroke-linecap": "round"
    }
  })]), _vm._v(" "), _c('g', {
    attrs: {
      "mask": "url(#mask1)"
    }
  }, [_c('rect', {
    attrs: {
      "x": "0",
      "width": "345",
      "height": "215",
      "fill": "#383838"
    }
  }), _vm._v(" "), _c('rect', {
    class: _vm.progressBar ? 'progress' : 'swoop',
    style: {
      '--progress-duration': _vm.progressBarTime + 'ms'
    },
    attrs: {
      "x": "0",
      "width": _vm.progressBar ? '100%' : 120,
      "height": "215",
      "fill": _vm.progressBar ? _vm.fillColor : 'url(#paint0_linear)'
    }
  })])]), _vm._v(" "), _c('defs', [_c('linearGradient', {
    attrs: {
      "id": "paint0_linear",
      "x1": "2.35084e-06",
      "y1": "58",
      "x2": "84",
      "y2": "58",
      "gradientUnits": "userSpaceOnUse"
    }
  }, [_c('stop', {
    attrs: {
      "stop-color": _vm.fillColor,
      "stop-opacity": "0"
    }
  }), _vm._v(" "), _c('stop', {
    attrs: {
      "offset": "0.468807",
      "stop-color": _vm.fillColor,
      "stop-opacity": "0.92"
    }
  }), _vm._v(" "), _c('stop', {
    attrs: {
      "offset": "1",
      "stop-color": _vm.fillColor,
      "stop-opacity": "0"
    }
  })], 1)], 1)]) : _vm._e(), _vm._v(" "), _vm.reason ? _c('p', {
    attrs: {
      "id": "reason"
    }
  }, [_vm._v(_vm._s(_vm.reason))]) : _vm._e(), _vm._v(" "), _vm.subReason ? _c('p', {
    attrs: {
      "id": "subReason"
    }
  }, [_vm._v(_vm._s(_vm.subReason))]) : _vm._e()])]);
};
var staticRenderFns = [];
export { render, staticRenderFns };