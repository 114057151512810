export default {
  name: 'ProgressIndicator',
  props: {
    step: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      totalSteps: 4
    };
  }
};